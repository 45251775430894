import { oldBrowserCheck } from '@spinnwerk/old-browser-check';

export function oldBrowserAlert({ selector = '.browser-alert--browsehappy' } = {}) {
    const element = document.querySelector(selector),
        oldBrowser = oldBrowserCheck({
            ie: 12,
            firefox: 60,
            chrome: 66,
            safari: 11,
        });

    if (oldBrowser && element) {
        element.style.display = 'block';
    }
}
