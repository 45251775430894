import { eventListenerOptions } from '@spinnwerk/polyfills';

export function serviceWorker() {
    // add service workers only in production mode
    if (process.env.NODE_ENV === 'production') {
        if ('serviceWorker' in navigator) {
            window.addEventListener(
                'load',
                () => {
                    navigator.serviceWorker.register('/service-worker.js');
                },
                eventListenerOptions({ passive: true }),
            );
        }
    }
}
