/** @module application */

async function autoScrolling(options) {
    let { AutoScrolling } = await import(/* webpackMode: "lazy" */ './auto-scrolling');

    return AutoScrolling.singleton(options);
}

import { bugsnag } from './bugsnag';
import { clearAway } from './clear-away';
import { globalState } from './globalState';
import { serviceWorker } from './service-worker';
import { showFocusOnTabKey } from './show-focus-on-tab-key';

export { autoScrolling, bugsnag, clearAway, globalState, serviceWorker, showFocusOnTabKey };
