export async function validation() {
    let $forms = $('form')
        .not('[novalidate]:not(.wpcf7-form)')
        .not('.cart')
        .not('.woocommerce-cart-form')
        .not('.woocommerce-checkout')
        .not('.woocommerce-MyAccount-content form');

    if ($forms.length) {
        let { initValidation } = await import(/* webpackMode: "lazy" */ './inc/validation.async');

        initValidation($forms);
    }
}
