import { eventListenerOptions } from '@spinnwerk/polyfills';

/**
 * Sets CSS variable for correct 100vh usage
 * @see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
export function fullHeightOnMobile() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

    window.addEventListener(
        'resize',
        () => {
            document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
        },
        eventListenerOptions({ passive: true }),
    );
}
