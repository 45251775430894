export function maps() {
    return new Promise((resolve) => {
        const mapElements = document.querySelectorAll('.map'),
            loadMaps = async () => {
                let { initMaps } = await import(/* webpackMode: "lazy" */ './map.async');

                initMaps(mapElements);
                resolve();
            };

        if (mapElements.length) {
            if ('BorlabsCookie' in window === false) {
                loadMaps();
            } else {
                // there are no events to get notified if the user consented to the use of google maps.
                // polling is the only possible way to detect.
                const pollConsent = () => {
                    // maps can be allowed globally via setting the cookie or individually, where the only indicator is,
                    // that the .map__iframe container is available (Borlabs removes it until the consent).
                    if (
                        window.BorlabsCookie.checkCookieConsent('googlemaps') ||
                        Array.prototype.some.call(mapElements, (el) => Boolean(el.querySelector('.map__iframe')))
                    ) {
                        loadMaps();
                    } else {
                        requestAnimationFrame(pollConsent);
                    }
                };

                pollConsent();
            }
        }
    });
}
