/** @module application */

/**
 * Handles loading fonts in a performing and UX-friendly way, mostly based on FOFT/FOUT with two stage render.
 * This means, the base font (often the normal-weight, normal-style variant) is loaded up-front (with preload tags) and
 * all other variants are loaded once this initial font is available.
 * @param {Object[]} fonts - List of fonts and their variants to load. See `app/etc/.fontsrc.json` for schema.
 * @returns {Promise<void>} Resolves once all fonts and their variants are either loaded or have failed.
 * @see https://www.zachleat.com/web/comprehensive-webfonts/#foft%2C-or-fout-with-two-stage-render
 */
export function loadFonts(fonts) {
    return new Promise((resolve) => {
        let allFontsToLoad = 0,
            allFontsLoadedCt = 0,
            loadedFonts = [],
            loadedFontsCt = {},
            usePolyfill = 'fonts' in document !== true, // = IE/Edge
            FontFaceObserver;

        fonts.forEach((font) => {
            allFontsToLoad++;

            font.variants.forEach(() => {
                allFontsToLoad++;
            });
        });

        async function loadFont({ name, variant = null }) {
            if (usePolyfill) {
                let font = new FontFaceObserver(name, variant);

                return font.load(null, 15000);
            } else {
                let variantString = variant ? `${[variant.weight, variant.style].join(' ')} ` : '';

                return document.fonts.load(`${variantString}1em ${name}`);
            }
        }

        function onVariantLoaded(name, variants) {
            if (typeof loadedFontsCt[name] === 'undefined') {
                loadedFontsCt[name] = 1;
            } else {
                loadedFontsCt[name]++;
            }

            if (loadedFontsCt[name] === variants.length) {
                loadedFonts.push(name);
            }
        }

        function indicateFontLoaded({ name, persisting = true }) {
            let saveName = encodeURIComponent(name);

            document.documentElement.classList.add(`font-${saveName}-loaded`);

            if (persisting && 'sessionStorage' in window) {
                try {
                    sessionStorage.setItem(`font${saveName}Loaded`, true);
                } catch (e) {
                    // access blocked
                }
            }
        }

        function finalize() {
            if (allFontsLoadedCt === allFontsToLoad) {
                loadedFonts.forEach((name) => {
                    indicateFontLoaded({ name });
                });

                resolve();
            }
        }

        function onFontLoaded() {
            allFontsLoadedCt++;

            finalize();
        }

        function onInitialFontLoaded({ name, variants }) {
            if (variants.length) {
                allFontsLoadedCt++;

                variants.forEach((variant) => {
                    loadFont({ name, variant })
                        .then(() => {
                            onVariantLoaded(name, variants);
                        })
                        .finally(onFontLoaded);
                });
            } else {
                onFontLoaded();
            }
        }

        function getFromSessionStorage({ name }) {
            let saveName = encodeURIComponent(name);

            if (!('sessionStorage' in window)) {
                return 'false';
            }

            try {
                return sessionStorage.getItem(`font${saveName}Loaded`);
            } catch (e) {
                // access blocked
                return 'false';
            }
        }

        function needsLoading() {
            return fonts.some((font) => getFromSessionStorage(font) !== 'true');
        }

        function load() {
            fonts.forEach((font) => {
                if (getFromSessionStorage(font) !== 'true') {
                    loadFont({ name: font.name + 'Initial' }).finally(() => {
                        onInitialFontLoaded(font);
                    });
                } else {
                    indicateFontLoaded({ name: font.name, persisting: false });
                }
            });
        }

        if (needsLoading()) {
            if (usePolyfill) {
                import(/* webpackMode: "lazy" */ 'fontfaceobserver').then((module) => {
                    FontFaceObserver = module.default;

                    load();
                });
            } else {
                load();
            }
        } else {
            loadedFonts = fonts.map(({ name }) => name);
            allFontsLoadedCt = allFontsToLoad;
            finalize();
        }
    });
}
