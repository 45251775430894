/** @module application */

import { eventListenerOptions } from '@spinnwerk/polyfills';

/**
 * Manages clickability of card components.
 * Clickable card components are identified by class `.card` and attribute `data-clickable`.
 * @see https://inclusive-components.design/cards/
 * @returns {Promise<void>}
 */
export async function cards() {
    document.querySelectorAll('.card[data-clickable]').forEach((card) => {
        let link = card.querySelector('a'),
            listenerOptions = eventListenerOptions({ passive: true }),
            down,
            downTarget,
            up,
            upTarget;

        // selecting text should not trigger a click, so the time between mouse down and mouse up is reduced to
        // trigger clicks less often.
        card.addEventListener(
            'mousedown',
            (event) => {
                down = Date.now();
                downTarget = event.target;
            },
            listenerOptions,
        );

        card.addEventListener(
            'mouseup',
            (event) => {
                up = Date.now();
                upTarget = event.target;

                if (up - down < 200 && downTarget === upTarget && upTarget !== link) {
                    if (event.button === 0) {
                        link.click();
                    } else if (event.button === 1) {
                        // emulate middle mouse button opening a new tab/window
                        let target = link.target,
                            hasNoReferrer = link.relList.contains('noreferrer');

                        link.target = '_blank';

                        if (!hasNoReferrer) {
                            link.relList.add('noreferrer');
                        }

                        link.click();

                        if (target) {
                            link.target = target;
                        } else {
                            link.removeAttribute('target');
                        }

                        if (!hasNoReferrer) {
                            link.relList.remove('noreferrer');
                        }
                    }
                }
            },
            listenerOptions,
        );
    });
}
